<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Users</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          to="/gpm/users/create"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>New User
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              dense
              v-model="search"
              label="Search"
            ></v-text-field>

            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="users"
              :search="search"
            >
              <template v-slot:item.name="{ item }">
                <v-btn
                  color="primary"
                  text
                  :to="`/gpm/users/${item.id}`"
                >{{ item.name }}</v-btn>
              </template>

              <template v-slot:item.status="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                >{{ item.status == 1 ? "Active" : "In Active" }}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      users: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Gate", value: "gates.Name" },
      ],
    };
  },
  methods: {
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gpm-users`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    changeStatus() {},
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped>
</style>